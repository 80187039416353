import React, { useEffect, useState } from "react";
import { HoldersData, TokenInfo } from "../interfaces/Interfaces"
import { useParams } from "react-router-dom";
import HoldersList from "../modules/HoldersList";
import { useNavigation } from "../hooks/useNavigation";
import { CHAIN_ID, ClientFactory, DefaultProviderUrls, bytesToU256, strToBytes } from "@massalabs/massa-web3";
import { LB_QUOTER_ADDRESS, IQuoter, Token, RouteV2, PairV2, WMAS as _WMAS, WETH as _WETH, USDC as _USDC, DAI as _DAI, USDT as _USDT, TradeV2, TokenAmount, parseUnits } from "@dusalabs/sdk";
import { cuteNumber } from "../functions/Functions";
import { useMyContext } from "../context/GlobalContext";

const Holders: React.FC = () => {
  const { goTo } = useNavigation();
  const { token, page } = useParams();
  const { massaPrice } = useMyContext();
  const [holders, setHolders] = useState<HoldersData[]>([]);
  const [nbPages, setnbPages] = useState<number>();
  const [supply, setSupply] = useState<bigint | undefined>();
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>();
  const [error, setError] = useState<string | null>(null);
  const [price, setPrice] = useState<number | undefined>();
  const [nbHolders, setNbHolders] = useState<number | undefined>();
  const [circSupply, setCircSupply] = useState<string | undefined>();
  
  const fetchHolders = async () => {
    try {
      let selectedPage = 0;
      if(page) {
        selectedPage = parseInt(page)-1;
        if(selectedPage < 0) selectedPage = 0;
      }

      const responseHolders = await fetch('https://api.massa.ga/holders/' + token + '/' + selectedPage);
      if (!responseHolders.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultHolders: HoldersData[] = await responseHolders.json();
      setHolders(resultHolders);
      
      if(resultHolders.length) setError(null);
      else setError('Invalid token address or no wallets.. (H)');
    } catch (error) {
      setError('Error retrieving data..');
      setHolders([]);
    }
  };

  const fetchNbHoldersetCircSupply = async () => {
    try {
      const responseHolders = await fetch('https://api.massa.ga/holders/' + token);
      if (!responseHolders.ok) {
        throw new Error('Error retrieving data..');
      }
      const resultHolders = await responseHolders.json();
      
      setNbHolders(resultHolders["NB_HOLDERS"]);
      setCircSupply(resultHolders["CIRCULATING_SUPPLY"]);

      if(resultHolders["NB_HOLDERS"]) setError(null);
      else setError('Invalid token address or no wallets.. (HC)');
    } catch (error) {
      setError('Error retrieving data..');
      setNbHolders(undefined);
      setCircSupply(undefined);
    }
  };

  const fetchNbPages = async () => {
    try {
      const responseNbPages = await fetch('https://api.massa.ga/holders_count/' + token);
      if (!responseNbPages.ok) {
        throw new Error('Error retrieving data..');
      }
      const resulNbPages = await responseNbPages.json();
      setnbPages(resulNbPages.NB_PAGES);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
      setnbPages(0);
    }
  };

  const fetchTokenInfo = async () => {
    try {
      const responseTI = await fetch('https://api.massa.ga/token_info/' + token);
      if (!responseTI.ok) {
        throw new Error('Error retrieving data..');
      }
      const resulTI = await responseTI.json();
      setTokenInfo(resulTI[0]);
      setError(null);
    } catch (error) {
      setError('Error retrieving data..');
    }
  };

  const fetchTokenSupply = async (token: string) => {
    setSupply(undefined);

    const webClient = await ClientFactory.createDefaultClient(DefaultProviderUrls.MAINNET, CHAIN_ID.MainNet, true);

    const res = await webClient.publicApi().getDatastoreEntries([{
      address: token,
      key: strToBytes("TOTAL_SUPPLY"),
    }]);

    if(res[0].final_value !== null) {
      try {
        setSupply(bytesToU256(res[0].final_value));
      } catch {
        setSupply(undefined);
      }
    }
  }

  const getPriceToken = async (token: TokenInfo) => {
    const chainId = 1;

    if(_WMAS[chainId].address === token.CONTRACT)
    {
      setPrice(massaPrice);
      return;
    }

    const inputToken = new Token(chainId, token.CONTRACT, token.DECIMALS, token.SYMBOL, token.NAME);
    const outputToken = _WMAS[chainId];

    const allTokenPairs = PairV2.createAllTokenPairs(
      inputToken,
      outputToken,
      [ inputToken, _WMAS[chainId], _WETH[chainId], _USDC[chainId], _DAI[chainId], _USDT[chainId] ],
    );
    
    const allPairs = PairV2.initPairs(allTokenPairs);

    const allRoutes = RouteV2.createAllRoutes(
      allPairs,
      inputToken,
      outputToken
    );

    const webClient = await ClientFactory.createDefaultClient(DefaultProviderUrls.MAINNET, CHAIN_ID.MainNet, true);

    const trades = await TradeV2.getTradesExactIn(
      allRoutes,
      new TokenAmount(inputToken, parseUnits('1', inputToken.decimals)),
      outputToken,
      false,
      false,
      webClient,
      chainId,
    );

    let bestTrade;

    try {
      bestTrade = TradeV2.chooseBestTrade(trades, true);
    } catch (error) {
      console.log("No route for: " + token.SYMBOL);
      return;
    }

    try {
      const quoter = new IQuoter(LB_QUOTER_ADDRESS[chainId], webClient);

      const prices = await quoter.findBestPathFromAmountIn(
        bestTrade.route.pathToStrArr(),
        parseUnits('1', inputToken.decimals).toString(),
      );

      const unitPrice = prices.virtualAmountsWithoutSlippage[ prices.virtualAmountsWithoutSlippage.length - 1 ];
      const usdValue = parseInt(unitPrice.toString())/Math.pow(10, outputToken.decimals)*massaPrice;
      setPrice(usdValue);
    } catch (error) {
      setPrice(undefined);
      console.log("Error for: " + token.SYMBOL);
      return;
    }
  }

  useEffect(() => {
    fetchTokenInfo();
    fetchNbHoldersetCircSupply();
    token && fetchTokenSupply(token);
    fetchHolders();
    fetchNbPages();
  }, [token, page]);

  useEffect(() => {
    tokenInfo && getPriceToken(tokenInfo);
  }, [tokenInfo]);

  return (
  <div>
    <div className="mt-3 mb-5">
      <div className="bg-yellow-50 border border-yellow-300 rounded text-yellow-700 p-2 mb-3 text-sm" role="alert"><span className="font-bold">Informations</span>: Balances are updated every 15 minutes.</div>
      <div className="text-xl mb-5 truncate"><kbd>{tokenInfo ? tokenInfo.NAME + " (" + tokenInfo.SYMBOL + ")" : token} holders</kbd><div>{token && <span role="button" className="inline-flex items-center text-sm text-blue-500 hover:underline mt-1" onClick={() => goTo('/token-explorer/token/' + token)}>Transactions <svg fill="currentColor" className="h-3 w-3 ms-1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14 3.5L8.5 9 7 7.5 12.5 2H10V0h6v6h-2V3.5zM6 0v2H2v12h12v-4h2v6H0V0h6z" fillRule="evenodd"/></svg></span>}</div></div>
      {error && <div className="text-red-500">{error}</div>}
      {tokenInfo &&
        <>
          <div className="hidden md:flex rounded py-3 bg-blue-100 mb-5">
            <div className="mx-auto uppercase text-center">
              <div className="text-blue-400">Holders</div>
              <div className="font-bold">{nbHolders !== undefined ? cuteNumber(nbHolders, 0) : "-"}</div>
            </div>
            <div className="mx-auto uppercase text-center">
              <div className="text-blue-400">Price</div>
              <div className="font-bold">${price !== undefined ? cuteNumber(price) : "-"}</div>
            </div>
            <div className="mx-auto uppercase text-center">
              <div className="text-blue-400">Total Supply</div>
              <div className="font-bold">{supply !== undefined ? cuteNumber(Number(supply)/Math.pow(10, tokenInfo.DECIMALS), 0) : "-"} {tokenInfo.SYMBOL}</div>
            </div>
            <div className="mx-auto uppercase text-center">
              <div className="text-blue-400">FDV</div>
              <div className="font-bold">${(supply !== undefined && price !== undefined) ? cuteNumber(Number(supply)/Math.pow(10, tokenInfo.DECIMALS)*price, 0) : "-"}</div>
            </div>
          </div>
          <div className="flex md:hidden rounded-t py-3 bg-blue-50">
            <div className="mx-auto w-1/2 uppercase text-center">
              <div className="text-blue-400">Holders</div>
              <div className="font-bold">{nbHolders !== undefined ? cuteNumber(nbHolders, 0) : "-"}</div>
            </div>
            <div className="mx-auto w-1/2 uppercase text-center">
              <div className="text-blue-400">Price</div>
              <div className="font-bold truncate">${price !== undefined ? cuteNumber(price) : "-"}</div>
            </div>
          </div>
          <div className="flex md:hidden rounded-b py-3 bg-blue-50 mb-5">
            <div className="mx-auto w-1/2 uppercase text-center">
              <div className="text-blue-400">Total Supply</div>
              <div className="font-bold">{supply !== undefined ? cuteNumber(Number(supply)/Math.pow(10, tokenInfo.DECIMALS), 0) : "-"} {tokenInfo.SYMBOL}</div>
            </div>
            <div className="mx-auto w-1/2 uppercase text-center">
              <div className="text-blue-400">FDV</div>
              <div className="font-bold">${(supply !== undefined && price !== undefined) ? cuteNumber(Number(supply)/Math.pow(10, tokenInfo.DECIMALS)*price, 0) : "-"}</div>
            </div>
          </div>
        </>
      }
      {tokenInfo && <HoldersList holders={holders} nbPages={(nbPages?nbPages:0)} decimals={tokenInfo.DECIMALS} symbol={tokenInfo.SYMBOL} supply={Number(supply)/Math.pow(10, tokenInfo.DECIMALS)} price={price} error={error}/>}
    </div>
  </div>
  )
};

export default Holders;
