import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PortfolioBar from "../modules/PortfolioBar";
import { CHAIN_ID, Client, ClientFactory, DefaultProviderUrls } from "@massalabs/massa-web3";
import PortfolioList from "../modules/PortfolioList";
import { copyToClipboard } from "../functions/Functions";
import { useNavigation } from "../hooks/useNavigation";

const Portfolio: React.FC = () => {
  const {address} = useParams();
  const { goTo } = useNavigation();
  const [addressValid, setAddressValid] = useState<boolean | undefined>(undefined);
  const [web3Client, setWeb3Client] = useState<Client>();

  const initClient = async () => {
    const client = await ClientFactory.createDefaultClient(DefaultProviderUrls.MAINNET, CHAIN_ID.MainNet);
    setWeb3Client(client);
  }
  
  const checkAddress = async (address: string) => {
    setAddressValid(undefined);
    if(address[1] === "S") {
      setAddressValid(true); 
      return;
    }
    if(!web3Client) return;
    try {
      const validAddress = await web3Client.wallet().getAccountBalance(address);
      if(validAddress !== null) setAddressValid(true);
      else setAddressValid(false);
    } catch {
      setAddressValid(false);
    }
  }

  useEffect(() => {
    initClient();
  }, [address]);

  useEffect(() => {
    if(address) checkAddress(address);
  }, [web3Client]);

  return (
    <div className="mt-3 mb-5">
      <div className="text-2xl truncate">Portfolio <span className="text-sm">mainnet</span></div>
      <PortfolioBar />
      {address && web3Client ? (addressValid ?
        <div>
          <div className="text-xl mb-5 truncate"><kbd>{address}</kbd><div>{address[1] !== "S" && <span role="button" className="inline-flex items-center text-sm text-blue-500 hover:underline mt-1" onClick={() => goTo('/token-explorer/address/' + address)}>Transactions <svg fill="currentColor" className="h-3 w-3 ms-1" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M14 3.5L8.5 9 7 7.5 12.5 2H10V0h6v6h-2V3.5zM6 0v2H2v12h12v-4h2v6H0V0h6z" fillRule="evenodd"/></svg></span>}</div></div>
          <PortfolioList address={address} web3Client={web3Client} />
        </div>
      :
        addressValid !== undefined ? <div className="text-red-600 bg-red-100 rounded p-2">Error: Address not valid..</div> : <div className="text-blue-500">Loading..</div>
      )
    :
      <div>
        <div className="text-xl truncate">Examples</div>
        <div className="text-sm truncate mt-2">
          <div>Smart contract</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP")}>AS12qzyNBDnwqq2vYwvUMHzrtMkVp6nQGJJ3TETVKF5HCd4yymzJP</span>
        </div>
        <div className="text-sm truncate mt-2">
          <div>Wallet address</div>
          <span className="font-bold size-20" onClick={() => copyToClipboard("AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE")}>AU1L3YbT7SBwxdVwzacoonEgou5oXi5mNfXMaXqYhoL69GVrDUrE</span>
        </div>
      </div>
    }
    </div>
  );
};

export default Portfolio;
