import { useParams } from 'react-router-dom';
import { useNavigation } from '../hooks/useNavigation';
import { HoldersData } from '../interfaces/Interfaces';
import Pagination from './Pagination';
import { cuteNumber } from '../functions/Functions';

interface ListTransfersProps {
    holders: HoldersData[];
    nbPages : number;
    decimals: number;
    symbol: string;
    supply: number | undefined;
    price: number | undefined;
    error: string | null;
}

function HoldersList({ holders, nbPages, decimals, symbol, supply, price,  error }: ListTransfersProps) { 
    const { goTo } = useNavigation();
    const { token, page } = useParams();
    const parsedPage = page ? parseInt(page) : 1;
    const currentPage = Number.isNaN(parsedPage) ? 1 : parsedPage;

    return (
        <div>
            <div className="hidden lg:grid grid-cols-12 gap-4 rounded-md text-sm text-blue-950 bg-blue-100 text-center font-bold py-5 uppercase">
                <div className="col-span-1">Rank</div>
                <div className="col-span-6 text-start">Address</div>
                <div className="col-span-2">Quantity</div>
                <div className="col-span-1">Percentage</div>
                <div className="col-span-2">Value</div>
            </div>
            <div className="hidden lg:block text-center border border-blue-100 rounded-md mt-1">
                {holders.map((holder, index) => (
                <div key={holder.ID || index} className="grid grid-cols-12 gap-4 py-2 rounded-md hover:bg-blue-50">
                    <div className="col-span-1">
                        {index+1+((parsedPage-1)*20)}
                    </div>
                    <div className="col-span-6 text-start truncate">
                        <span role="button" className="flex text-blue-500 hover:underline" onClick={() => goTo('/portfolio/' + holder.ADDRESS)}>{String(holder.ADDRESS)[1] === "S" && <svg fill="currentColor" viewBox="0 0 1024 1024" className="icon h-4 w-4 me-1 relative top-1"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M182.88 146.2h585.14v365.71h73.14V73.06H109.74v877.71h402.28v-73.14H182.88z" /><path d="M256.01 219.34h438.86v73.14H256.01zM256.01 365.63h365.71v73.14H256.01zM256.01 511.91h219.43v73.14H256.01zM731.44 545.76L548.59 646.33v201.14l182.86 100.57L914.3 847.47V646.33L731.44 545.76z m109.72 258.46l-109.71 60.34-109.71-60.34V689.58l109.71-60.34 109.71 60.34v114.64z" /><path d="M731.44 746.9m-36.57 0a36.57 36.57 0 1 0 73.14 0 36.57 36.57 0 1 0-73.14 0Z" /></svg>} {holder.ADDRESS}</span>
                    </div>
                    <div className="col-span-2 truncate">
                        {parseInt(holder.BALANCE)/Math.pow(10, decimals) >= 0.001 ?
                            cuteNumber((parseInt(holder.BALANCE)/Math.pow(10, decimals)), 3)
                        :
                            "< 0.001"
                        } 
                    </div>
                    <div className="col-span-1 truncate">
                        {supply ? (parseInt(holder.BALANCE)/Math.pow(10, decimals)*100/supply).toFixed(2) : "-"} %
                    </div>
                    <div className="col-span-2 truncate">
                        ${price && (parseInt(holder.BALANCE)/Math.pow(10, decimals))*price >= 0.01 ?
                            cuteNumber(((parseInt(holder.BALANCE)/Math.pow(10, decimals))*price), 2)
                        :
                            price !== undefined ? (price !== 0 ? "< 0.01" : "-") : "-"
                        }
                    </div>
                </div>
                ))}
            </div>
            <div className="lg:hidden">
                {error ?
                    <div className="text-left text-red-500 p-2">{error}</div>
                : null }
                {holders.map((holder, index) => (
                    <div key={holder.ID || index} className="text-center border border-blue-100 rounded-md mt-1 hover:bg-blue-50">
                        <div className="truncate bg-blue-100 rounded-md p-1">#{index+1+((parsedPage-1)*20)} <span role="button" className="text-blue-500 font-bold hover:underline" onClick={() => goTo('/portfolio/' + holder.ADDRESS)}>{String(holder.ADDRESS)[1] === "S" && <span className='inline-flex'><svg fill="currentColor" viewBox="0 0 1024 1024" className="icon h-4 w-4 me-1 relative top-1"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M182.88 146.2h585.14v365.71h73.14V73.06H109.74v877.71h402.28v-73.14H182.88z" /><path d="M256.01 219.34h438.86v73.14H256.01zM256.01 365.63h365.71v73.14H256.01zM256.01 511.91h219.43v73.14H256.01zM731.44 545.76L548.59 646.33v201.14l182.86 100.57L914.3 847.47V646.33L731.44 545.76z m109.72 258.46l-109.71 60.34-109.71-60.34V689.58l109.71-60.34 109.71 60.34v114.64z" /><path d="M731.44 746.9m-36.57 0a36.57 36.57 0 1 0 73.14 0 36.57 36.57 0 1 0-73.14 0Z" /></svg></span>}{holder.ADDRESS}</span></div>
                        <div className="grid grid-cols-5 px-1">
                            <div className="truncate col-span-4 text-start">
                                {parseInt(holder.BALANCE)/Math.pow(10, decimals) >= 0.001 ?
                                    cuteNumber((parseInt(holder.BALANCE)/Math.pow(10, decimals)), 3)
                                :
                                    "< 0.001"
                                } {symbol}
                                <div className='text-sm italic'>
                                    ${price && (parseInt(holder.BALANCE)/Math.pow(10, decimals))*price >= 0.01 ?
                                        cuteNumber(((parseInt(holder.BALANCE)/Math.pow(10, decimals))*price), 2)
                                    :
                                        price !== undefined ? (price !== 0 ? "< 0.01" : "-") : "-"
                                    }
                                </div>
                            </div>
                            <div className="truncate col-span-1 text-end">
                                <span>{supply ? (parseInt(holder.BALANCE)/Math.pow(10, decimals)*100/supply).toFixed(2) : "-"} %</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {nbPages?
                <div className="text-center mt-2">
                    <Pagination nbPages={nbPages} page={currentPage} path={'/holders/' + token} />
                </div>
            : null }
        </div>
    );
}
        
export default HoldersList;